import { MediaManager } from './media_manager';
import { Auth } from 'Shared/auth';
import { MediaItem } from './item';

/**
 * See docs in the README for this directory
 *
 * Represents a location that a MediaItem can be placed on the page.
 *
 * Events:
 *    browse(): fired when an action is taken by the user that indicates they
 *       want to change the MediaItem which is displayed in this target.
 *
 *    itemChanged(oldItem, newItem): fires when the current mediaItem is
 *       replaced by another one.
 *
 *    fieldUpdated(): fires when the form field is updated with a new mediaid.
 *       This will fire when a different mediaItem is attached or when the
 *       current mediaItem is modified(cropped, markers)
 */
export const MediaTarget = new Class({
   Implements: [Options, Events],

   options: {
      getErrorMessage: function () {
         return _js("Can't place this item here.");
      },
   },

   element: null,
   mediaid: null,
   _isDirty: false,

   initialize: function (el, options) {
      this.element = $(el);
      let target = this;

      // Load default values from data attributes

      let initialOptions = this.options;
      initialOptions.accepts = el.get('data-accepts');
      initialOptions.displaySize = el.get('data-displaySize');
      initialOptions.formField = $(el.get('data-formField'));
      // Grab the list of menu options to ignore.
      // Note: if this is set to 'all', we don't draw the menu at all.
      initialOptions.menuHide = el.get('data-menuHide');

      // override default options with those from data attributes
      // This copies from options to this. (doesn't actually touch the above)
      Object.append(initialOptions, options);

      // If the form field is inside, we need to be careful with el.empty()
      if (el.contains(initialOptions.formField)) {
         this._formFieldIsInside = true;
      }

      el.store('mediaTarget', this);
      this._content = el.getElement('.contents');

      // So it can easily be used as an event handler
      let updateFormField = this._updateFormField.bind(this);
      // Keep these in a group so they can easily be added / removed from
      // media items.
      this._mediaItemEventHandlers = {
         dataChanged: updateFormField,
         deleted: function () {
            target.attachItem(null);
         },
      };

      // When my item changes, update the form field
      this.addEvent('itemChanged', updateFormField);

      el.addClass('mediaTarget');

      let itemData = el.get('data-itemData');
      if (itemData) {
         let item = MediaItem.createFromData(JSON.parse(itemData));
         this._immediatelyAttach(item);
         this._isDirty = false;
      } else {
         this._displayItem();
      }

      this._onclick = function () {
         if (!this.hasClass('js-no-browse')) {
            target.fireEvent('browse');
         }
      };

      this._enableUI();
   },

   _enableUI: function () {
      // Inform listeners that the user wants to browse their media
      this.element.removeEvents('click');
      this.element.addEvent('click', this._onclick);
      this.element.removeClass('disabled');

      if (this._disabledOverlay) {
         this._disabledOverlay.dispose();
      }

      let alterTarget = this.element.getElement('.alterTarget');
      if (alterTarget) {
         alterTarget.removeClass('disabled');
      }
   },

   enable: function () {
      this._enableUI();
   },

   _disableUI: function () {
      this.element.removeEvents('click');
      this.element.addClass('disabled');
      this._disabledOverlay = this._disabledOverlay || new Element('div.disabledOverlay');
      this.element.grab(this._disabledOverlay);
      // TODO: Sometimes _displayItem doesn't get executed before
      // this is called, and there's no .alterTarget element.
      when(this.element.getElement('.alterTarget'), alterTarget => {
         alterTarget.addClass('disabled');
      });
   },

   disable: function () {
      this._disableUI();
   },

   getErrorMessage: function (mediaItem) {
      return this.options.getErrorMessage(mediaItem);
   },

   clear: function () {
      this._displayItem(null);
   },

   /**
    * Display the given media item in this target.
    */
   _displayItem: function (mediaItem) {
      let self = this;
      if (!mediaItem) {
         self._empty();
         let contents = new Element('div.contents');
         let size = this.options.displaySize;
         let iconClass = self.options.iconClass || 'addImage';
         contents.addClass(size);
         contents.grab(MediaTarget.createModifyableIndicator(iconClass, size));
         self.element.grab(contents);
         return;
      }

      /**
       * We only need to display it once, further updates to the mediaItem
       * are handled by the Representation itself.
       */
      self._displayLoading(true);
      mediaItem.createRepresentationFor(self, itemDisplay => {
         self._itemDisplay = itemDisplay;
         let displayElement = itemDisplay.getElement();
         displayElement.addClass('contents');

         self._content = displayElement;
         self._empty();
         displayElement.grab(
            MediaTarget.createModifyableIndicator('replaceImage', self.options.displaySize)
         );
         self.element.grab(displayElement);
         self._displayLoading(false);
      });
   },

   /**
    * Calls this.element.empty and re-adds the formField if it's inside the
    * target so it doesn't disappear.
    */
   _empty: function _empty() {
      this.element.empty();
      if (this._formFieldIsInside) {
         this.element.grab(this.options.formField);
      }
   },

   /**
    * Apply or remove a "loading" style to this target.
    */
   _displayLoading: function (isLoading) {
      let element = this.element;
      let self = this;

      if (isLoading) {
         element.pinDimensions();
         this.element.toggleClass('mediaItemProcessing', isLoading);
         let overlay = this._overlay();
         this.element.grab(overlay);
      } else {
         const hideLoading = function () {
            let overlay = self._overlay();
            overlay.dispose();
            element.unpinDimensions();
            self.element.toggleClass('mediaItemProcessing', isLoading);
         };

         let itemDisplay = this._itemDisplay;
         if (itemDisplay && itemDisplay.whenReady) {
            itemDisplay.whenReady(hideLoading);
         } else {
            hideLoading();
         }
      }
   },

   _overlay: function () {
      this._overlayEl = this._overlayEl || new Element('div.loadingOverlay');
      return this._overlayEl;
   },

   /**
    * Update the form field with the current media item id
    */
   _updateFormField: function () {
      if (this.options.formField) {
         let id = this.mediaItem ? this.mediaItem.getID() : '';
         this.options.formField.set('value', id);
         this.options.formField.fireEvent('input');
         this.fireEvent('fieldUpdated');
      }
   },

   /**
    * Attach a new media item to this target. This calls the customAttach
    * optional function if one is set. If you set a customAttach function,
    * you'll have to call target._immediatelyAttach() yourself and you'll want
    * to verify mediaItem.data.filter_state() === true
    *
    * If you are updating the media item AND attaching it to a target,
    * make sure to call mediaItem.setDataPromise() BEFORE this function.
    * Otherwise the target will breifly display the old mediaItem before you
    * update it.
    *
    * @param mediaItem the item to attach
    * @param finished(oldItem, success, errorMsg) callback that will be called
    *        when the item has been attached.
    */
   attachItem: function (mediaItem, finished) {
      finished = finished || function () {};

      let customAttach = this.options.customAttach;
      if (customAttach) {
         Reflect.apply(customAttach, this, [mediaItem, finished]);
      } else {
         if (mediaItem && mediaItem.data.filter_state() === false) {
            return finished(mediaItem, false, this.getErrorMessage(mediaItem));
         }

         let old = this._immediatelyAttach(mediaItem);
         if (finished) {
            finished(old, true);
         }
      }
   },

   /**
    * The underlying attach function which sets the dirty flag and displays the
    * item.
    */
   _immediatelyAttach: function (mediaItem) {
      let old = this._setItem(mediaItem);
      this._isDirty = true;
      this._displayItem(mediaItem);
      return old;
   },

   /**
    * Returns the DOM <input> element that stores the id.
    */
   getFormField: function () {
      return this.options.formField;
   },

   /**
    * Returns true if the state of this target has not been saved to the DB
    * (it can be undone without an API call)
    */
   isDirty: function () {
      return this._isDirty;
   },

   /**
    * Return an array of name => boolean mappings that enable or disable
    * various mediaItem menu options.
    */
   getMenuItems: function (mediaItem) {
      let menuItems = {
         detach: Auth.isLoggedIn(),
      };
      let hiddenMenuItems = this.options.menuHide.split(' ');

      hiddenMenuItems.each(menuItem => {
         menuItems[menuItem] = false;
      });

      return menuItems;
   },

   /**
    * Returns the name of the filter collection that a media Item must pass
    * to be attached to this target.
    *
    * See: MediaFilters.php
    */
   getMediaFilterName: function () {
      return this.options.accepts;
   },

   /**
    * Set the current mediaItem that's attached to this target.  This
    * is meant to be used for initialization, use attachItem to actually
    * change which item is attached.
    */
   _setItem: function (mediaItem) {
      let old = null;
      if (this.mediaItem) {
         this.mediaItem.removeEvents(this._mediaItemEventHandlers);
         this.mediaItem.setContext();
         old = this.mediaItem;
      }

      if (mediaItem) {
         mediaItem.addEvents(this._mediaItemEventHandlers);
         mediaItem.setContext(this);
      }
      this.mediaItem = mediaItem;
      this.fireEvent('itemChanged', [mediaItem, old]);

      return old;
   },

   /**
    * Removes the main element from the DOM
    */
   destroy: function () {
      this.element.destroy();
   },

   getMediaItem: function () {
      return this.mediaItem;
   },
});

Object.append(MediaTarget, {
   registerAllTargets: function () {
      return $$('.mediaTarget')
         .filter(target => !target.hasClass('customSetup'))
         .map(target => MediaTarget.createFromElement(target));
   },

   createFromElement: function (element, options) {
      let target = new MediaTarget(element, options);
      target.addEvent('browse', MediaTarget._initiateBrowsing);
      return target;
   },

   /**
    * Create an element that indicates the target is modifyable
    *
    * $className:  one of 'addImage' or 'replaceImage'
    */
   createModifyableIndicator: function (className, size) {
      let container = new Element('div', { class: 'alterTarget ' + size + ' ' + className });
      let icon = new Element('div.icon');
      container.grab(icon);
      return container;
   },

   _initiateBrowsing: function () {
      // 'this' is the mediaTarget cause we are consuming it's event
      MediaManager.browse(this);
   },
});
